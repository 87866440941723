define("shared/components/cluster-driver/driver-rke/component", ["exports", "@rancher/ember-api-store/utils/validate", "ember-deep-set", "js-yaml", "moment", "semver", "shared/components/cru-cloud-provider/cloud-provider-info", "shared/mixins/cluster-driver", "shared/mixins/manage-labels", "shared/utils/constants", "shared/utils/parse-version", "shared/utils/util", "ui/components/input-text-file/component", "shared/components/cluster-driver/driver-rke/template"], function (_exports, _validate, _emberDeepSet, _jsYaml, _moment, _semver, _cloudProviderInfo, _clusterDriver, _manageLabels, _constants, _parseVersion, _util, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EXCLUDED_KEYS = ['name'];
  const EXCLUDED_CHILDREN_KEYS = ['extra_args', 'nodelocal', 'dns', 'extraArgs'];

  function camelToUnderline(str, split = true) {
    str = str || '';

    if (str.indexOf('-') > -1 || str.endsWith('CloudProvider')) {
      return str;
    } else if (split) {
      return (str || '').dasherize().split('-').join('_');
    } else {
      return (str || '').dasherize();
    }
  }

  const EXCLUED_CLUSTER_OPTIONS = ['annotations', 'labels'];
  const AUTHCHOICES = [{
    label: 'clusterNew.rke.auth.x509',
    value: 'x509'
  }];
  const INGRESSCHOICES = [{
    label: 'clusterNew.rke.ingress.nginx',
    value: 'nginx'
  }, {
    label: 'clusterNew.rke.ingress.none',
    value: 'none'
  }];
  const AVAILABLE_STRATEGIES = ['local', 's3'];
  const {
    CLUSTER_TEMPLATE_IGNORED_OVERRIDES,
    NETWORK_CONFIG_DEFAULTS: {
      DEFAULT_BACKEND_TYPE
    }
  } = _constants.default;
  const {
    WEAVE
  } = _constants.default.NETWORK_CONFIG_DEFAULTS;

  var _default = _component.default.extend(_manageLabels.default, _clusterDriver.default, {
    globalStore: Ember.inject.service(),
    settings: Ember.inject.service(),
    growl: Ember.inject.service(),
    intl: Ember.inject.service(),
    clusterTemplates: Ember.inject.service(),
    access: Ember.inject.service(),
    router: Ember.inject.service(),
    cisHelpers: Ember.inject.service(),
    features: Ember.inject.service(),
    layout: _template.default,
    authChoices: AUTHCHOICES,
    ingressChoices: INGRESSCHOICES,
    availableStrategies: AVAILABLE_STRATEGIES,
    ingornedRkeOverrides: CLUSTER_TEMPLATE_IGNORED_OVERRIDES,
    configField: 'rancherKubernetesEngineConfig',
    registry: 'default',
    accept: '.yml, .yaml',
    backupStrategy: 'local',
    overrideCreatLabel: null,
    loading: false,
    pasteOrUpload: false,
    model: null,
    initialVersion: null,
    registryUrl: null,
    registryUser: null,
    registryPass: null,
    clusterOptErrors: null,
    existingNodes: null,
    initialNodeCounts: null,
    step: 1,
    token: null,
    taints: null,
    labels: null,
    etcd: false,
    controlplane: false,
    worker: true,
    defaultDockerRootDir: null,
    nodePoolErrors: null,
    windowsEnable: false,
    isLinux: true,
    weaveCustomPassword: false,
    clusterTemplateCreate: false,
    clusterTemplateQuestions: null,
    forceExpandOnInit: false,
    forceExpandAll: false,
    applyClusterTemplate: null,
    useClusterTemplate: false,
    clusterTemplatesEnforced: false,
    selectedClusterTemplateId: null,
    upgradeStrategy: null,
    scheduledClusterScan: null,
    isWindowsPreferedCluster: false,
    isPostSave: false,
    isNew: Ember.computed.equal('mode', 'new'),
    isEdit: Ember.computed.equal('mode', 'edit'),
    notView: Ember.computed.or('isNew', 'isEdit'),
    clusterState: Ember.computed.alias('model.originalCluster.state'),
    // Custom stuff
    isCustom: Ember.computed.equal('nodeWhich', 'custom'),
    isScheduledClusterScanDisabled: Ember.computed.alias('model.cluster.windowsPreferedCluster'),

    init() {
      this._super(...arguments);

      Ember.setProperties(this, {
        upgradeStrategy: {},
        scheduledClusterScan: {
          enabled: false
        }
      });
      this.initNodeCounts();

      if (!this.useClusterTemplate && this.model.cluster.clusterTemplateRevisionId) {
        Ember.setProperties(this, {
          useClusterTemplate: true,
          forceExpandOnInit: true,
          selectedClusterTemplateId: this.cluster.clusterTemplateId
        });
      }

      if (this.applyClusterTemplate) {
        if (this.useClusterTemplate) {
          this.initTemplateCluster();
        } else {
          this.initNonTemplateCluster();
        }
      } else {
        if (this.model.cluster.clusterTemplateRevisionId && this.router.currentRouteName !== 'global-admin.cluster-templates.new') {
          this.initTemplateCluster();
        } else {
          this.initNonTemplateCluster();

          if (this.clusterTemplateCreate && this.clusterTemplateQuestions && this.clusterTemplateQuestions.length > 0) {
            // cloned
            this.clusterTemplateQuestions.forEach(q => {
              this.send('addOverride', true, {
                path: q.variable
              });
            });
          }
        }
      }

      Ember.setProperties(this, {
        scheduledClusterScan: {
          enabled: false,
          scheduleConfig: {
            cronSchedule: '0 0 * * *',
            retention: 24
          },
          scanConfig: {
            cisScanConfig: {
              failuresOnly: false,
              skip: null,
              profile: null,
              overrideBenchmarkVersion: null
            }
          }
        },
        upgradeStrategy: {
          maxUnavailableControlplane: '',
          maxUnavailableWorker: '',
          drain: 'false',
          nodeDrainInput: {}
        }
      }); // This needs to be scheduled after render in order to wait for initTemplateCluster to setup the rkeconfig defaults.

      Ember.run.scheduleOnce('afterRender', this, this.setupComponent);
    },

    didReceiveAttrs() {
      if (this.applyClusterTemplate) {
        if (this.useClusterTemplate) {
          this.initTemplateCluster();
        } else {
          if (!this.clusterTemplateCreate && Ember.isEmpty(this.cluster.rancherKubernetesEngineConfig)) {
            this.initNonTemplateCluster();
          }
        }
      }

      if (this.useClusterTemplate && this.model.cluster.clusterTemplateRevisionId) {
        Ember.set(this, 'forceExpandAll', true);
      }
    },

    actions: {
      fileUploaded() {
        let {
          yamlValue
        } = this;
        Ember.run.next(() => {
          Ember.set(this, 'clusterOptErrors', this.checkYamlForRkeConfig(yamlValue));
        });
      },

      yamlValUpdated(yamlValue, codeMirror) {
        if (!this.codeMirror) {
          codeMirror.on('paste', this.pastedYaml.bind(this));
        } // this fires when we first change over so here is where you can set the paste watcher to validate


        Ember.setProperties(this, {
          yamlValue,
          codeMirror
        });
      },

      addOverride(enabled, paramsToOverride, hideQuestion = false) {
        let {
          primaryResource,
          clusterTemplateQuestions = []
        } = this;
        let {
          path
        } = paramsToOverride;
        let question = null;
        let questionsSchemas = [];
        let {
          clusterTemplateRevision
        } = this.model;

        if (clusterTemplateRevision && (this.clusterTemplates.questionsSchemas || []).length > 0) {
          questionsSchemas = this.clusterTemplates.questionsSchemas;
        }

        if (enabled) {
          if (!clusterTemplateQuestions.findBy('variable', path)) {
            if (path.startsWith('uiOverride')) {
              question = questionsSchemas.findBy('variable', path);

              if (!question) {
                question = this.globalStore.createRecord({
                  type: 'question',
                  variable: path,
                  primaryResource
                });
              }

              hideQuestion = true;
              Ember.setProperties(question, {
                type: 'boolean',
                default: true,
                hideQuestion
              });
              clusterTemplateQuestions.pushObject(question);
            } else {
              question = questionsSchemas.findBy('variable', path);

              if (question) {
                if (question.variable === 'rancherKubernetesEngineConfig.kubernetesVersion') {
                  question = this.parseKubernetesVersionSemVer(primaryResource, question);
                }

                if (question.variable.includes('azureCloudProvider')) {
                  let cloudProviderMatch = _cloudProviderInfo.azure[(question.variable.split('.') || []).lastObject];

                  if (cloudProviderMatch) {
                    let {
                      required = false,
                      type = 'string'
                    } = cloudProviderMatch;
                    question = this.globalStore.createRecord({
                      type: 'question',
                      variable: path
                    });
                    Ember.setProperties(question, {
                      required,
                      type
                    });

                    if (required) {
                      Ember.set(question, 'forceRequired', true);
                    }
                  }
                }
              } else {
                question = this.globalStore.createRecord({
                  type: 'question',
                  variable: path
                });
                Ember.set(question, 'type', 'string');
              }

              Ember.setProperties(question, {
                primaryResource,
                isBuiltIn: true,
                hideQuestion
              });

              if (path.includes('rancherKubernetesEngineConfig.privateRegistries[0]')) {
                // need to replace the array target with built in first object so the alias works
                path = path.replace('[0]', '.firstObject');
              }

              Ember.defineProperty(question, 'default', Ember.computed.alias(`primaryResource.${path}`));
              clusterTemplateQuestions.pushObject(question);
            }
          } else {
            // we've found the template override this wires up the value displayed in overrides section to the form value
            question = clusterTemplateQuestions.findBy('variable', path);
            Ember.setProperties(question, {
              primaryResource,
              isBuiltIn: true,
              hideQuestion
            });
            Ember.defineProperty(question, 'default', Ember.computed.alias(`primaryResource.${path}`));
          }
        } else {
          if (path === 'uiOverrideBackupStrategy') {
            question = clusterTemplateQuestions.findBy('variable', 'rancherKubernetesEngineConfig.services.etcd.backupConfig.s3BackupConfig');
            clusterTemplateQuestions.removeObject(question);
          } else {
            question = clusterTemplateQuestions.findBy('variable', path);
            clusterTemplateQuestions.removeObject(question);
          }
        }

        Ember.set(this, 'clusterTemplateQuestions', clusterTemplateQuestions);
      },

      setCpFields(configName, cloudProviderConfig) {
        Ember.set(this, `config.cloudProvider.${configName}`, cloudProviderConfig);
      },

      cancel() {
        const newOpts = this.getOptsFromYaml();

        if (newOpts) {
          if (this.updateFromYaml && this.mode !== 'view') {
            this.updateFromYaml(newOpts);
            Ember.setProperties(this, {
              clusterOptErrors: [],
              pasteOrUpload: false
            });
          }
        } else {
          if (Ember.isEmpty(this.clusterOptErrors)) {
            Ember.set(this, 'pasteOrUpload', false);
          }
        }
      },

      showPaste() {
        if (!this.configBeforeYaml) {
          // need to store off a raw non-referneced version of the og config
          // if a user switches back to form view and then comes back to yaml to
          // move something to a lower indent or remove we want to only add whats in
          // the orignal config and waht is in the yaml.
          // add the key bloop: true to yaml, sswitch to form, wait no i want bloop nested under something else, comeback and move it, now you have two keys, one at root and dupe at new nest
          Ember.set(this, 'configBeforeYaml', this.primaryResource.clone());
        }

        Ember.set(this, 'pasteOrUpload', true);
      },

      addRegistry(pr) {
        const config = Ember.get(this, 'config');

        if ((config.privateRegistries || []).length <= 0) {
          Ember.set(config, 'privateRegistries', [pr]);
        } else {
          config.privateRegistries.pushObject(pr);
        }
      },

      removeRegistry(pr) {
        Ember.get(this, 'config.privateRegistries').removeObject(pr);
      },

      setTaints(taints) {
        Ember.set(this, 'taints', taints);
      }

    },
    windowsPreferedClusterChanged: Ember.observer('model.cluster.windowsPreferedCluster', function () {
      if (Ember.get(this, 'model.cluster.windowsPreferedCluster')) {
        Ember.set(this, 'scheduledClusterScan.enabled', 'false');
      }
    }),
    usingClusterTemplate: Ember.observer('useClusterTemplate', function () {
      if (!this.useClusterTemplate && this.model.cluster.clusterTemplateRevisionId) {
        Ember.set(this, 'clusterTemplateRevisionId', null);
      }
    }),
    driverDidChange: Ember.observer('nodeWhich', function () {
      this.createRkeConfigWithDefaults();
    }),
    isLinuxChanged: Ember.observer('isLinux', function () {
      if (Ember.get(this, 'nodeWhich') !== 'custom') {
        return;
      }

      const isLinux = Ember.get(this, 'isLinux');

      if (!isLinux) {
        Ember.setProperties(this, {
          controlplane: false,
          etcd: false,
          worker: true
        });
      }
    }),
    strategyChanged: Ember.observer('backupStrategy', function () {
      const {
        backupStrategy,
        globalStore
      } = this;
      const services = this.config.services.clone();

      switch (backupStrategy) {
        case 'local':
          if (services) {
            Ember.setProperties(services.etcd, {
              backupConfig: globalStore.createRecord({
                type: 'backupConfig',
                s3BackupConfig: null,
                enabled: Ember.get(services, 'etcd.backupConfig.enabled') || true
              })
            });
          }

          break;

        case 's3':
          if (services) {
            Ember.setProperties(services.etcd, {
              backupConfig: globalStore.createRecord({
                type: 'backupConfig',
                s3BackupConfig: globalStore.createRecord({
                  type: 's3BackupConfig'
                }),
                enabled: Ember.get(services, 'etcd.backupConfig.enabled') || true
              })
            });
          }

          break;

        default:
          break;
      }

      Ember.set(this, 'config.services', services);
    }),
    onCisScanBenchmarksUpdated: Ember.observer('cisHelpers.cisScanBenchmarks.[]', function () {
      this.initScheduledClusterScan();
    }),
    enforcementChanged: Ember.on('init', Ember.observer('settings.clusterTemplateEnforcement', function () {
      let {
        access: {
          me: {
            hasAdmin: globalAdmin = null
          }
        },
        settings: {
          clusterTemplateEnforcement = false
        }
      } = this;
      let useClusterTemplate = false;

      if (!globalAdmin) {
        // setting is string value
        if (clusterTemplateEnforcement === 'true') {
          clusterTemplateEnforcement = true;
        } else {
          clusterTemplateEnforcement = false;
        }

        if (this.applyClusterTemplate) {
          if (clusterTemplateEnforcement) {
            useClusterTemplate = true;
          } else if (this.model.cluster.clusterTemplateRevisionId) {
            useClusterTemplate = true;
          }
        } else if (!this.applyClusterTemplate && clusterTemplateEnforcement) {
          useClusterTemplate = true;
        } else {
          if (this.model.cluster.clusterTemplateRevisionId) {
            useClusterTemplate = true;
          }
        }

        Ember.setProperties(this, {
          useClusterTemplate,
          clusterTemplatesEnforced: clusterTemplateEnforcement
        });
      }
    })),
    agentEnvVars: Ember.computed('cluster.agentEnvVars.[]', {
      get() {
        var _this$cluster;

        if (!((_this$cluster = this.cluster) !== null && _this$cluster !== void 0 && _this$cluster.agentEnvVars)) {
          // if undefined two way binding doesn't seem to work
          Ember.set(this, 'cluster.agentEnvVars', []);
        }

        return Ember.get(this, 'cluster.agentEnvVars') || [];
      },

      set(_key, value) {
        Ember.set(this, 'cluster.agentEnvVars', value);
        return value;
      }

    }),
    maxUnavailable: Ember.computed('upgradeStrategy.maxUnavailableUnit', 'upgradeStrategy.maxUnavailableWorker', function () {
      const value = {
        value: Ember.get(this, 'upgradeStrategy.maxUnavailableWorker')
      };
      const isPercentageUnits = Ember.get(this, 'upgradeStrategy.maxUnavailableUnit') === 'percentage'; // Strip off the % at the end of the value, as we will add it again when we format it

      if (isPercentageUnits && typeof value.value === 'string') {
        const PERCENT_REGEX = /%+$/;
        value.value = value.value.replace(PERCENT_REGEX, '');
      }

      return isPercentageUnits ? Ember.get(this, 'intl').t('clusterNew.rke.upgradeStrategy.maximumWorkersDown.view.percentage', value) : Ember.get(this, 'intl').t('clusterNew.rke.upgradeStrategy.maximumWorkersDown.view.count', value);
    }),
    showUpgradeK8s21Warning: Ember.computed('initialVersion', 'config.kubernetesVersion', function () {
      const isLegacyEnabled = Ember.get(this, 'features').isFeatureEnabled(_constants.default.FEATURES.LEGACY);

      if (!isLegacyEnabled) {
        return false;
      }

      const selectedVersion = (0, _parseVersion.coerceVersion)(Ember.get(this, 'config.kubernetesVersion'));
      return _semver.default.satisfies(selectedVersion, '>=1.21.0');
    }),
    showUpgradeK8sWarning: Ember.computed('selectedClusterTemplateId', 'applyClusterTemplate', 'initialVersion', 'model.clusterTemplateRevision.clusterConfig.rancherKubernetesEngineConfig.kubernetesVersion', function () {
      const {
        applyClusterTemplate,
        initialVersion,
        isEdit
      } = this;
      const ctrK8sVersion = Ember.get(this, 'model.clusterTemplateRevision.clusterConfig.rancherKubernetesEngineConfig.kubernetesVersion');

      try {
        if (isEdit && applyClusterTemplate) {
          if (initialVersion && ctrK8sVersion && _semver.default.lt(initialVersion, ctrK8sVersion)) {
            return true;
          }
        }
      } catch (err) {
        return false;
      }

      return false;
    }),
    filteredClusterTemplates: Ember.computed('model.clusterTemplates.@each.{id,state,name,members}', function () {
      let {
        model: {
          clusterTemplates = []
        }
      } = this;
      let mapped = clusterTemplates.map(clusterTemplate => {
        return {
          name: clusterTemplate.name,
          id: clusterTemplate.id
        };
      });
      return mapped.sortBy('created').reverse();
    }),
    maxUnavailableNodesOptions: Ember.computed(function () {
      return [{
        name: this.intl.t('clusterNew.rke.upgradeStrategy.maximumWorkersDown.mode.percentage'),
        id: 'percentage'
      }, {
        name: this.intl.t('clusterNew.rke.upgradeStrategy.maximumWorkersDown.mode.count'),
        id: 'count'
      }];
    }),
    filteredTemplateRevisions: Ember.computed('cluster.type', 'isEdit', 'model.clusterTemplateRevisions.@each.{id,members,name,state}', 'selectedClusterTemplateId', 'setDefaultRevisionId', function () {
      let {
        selectedClusterTemplateId,
        clusterTemplateRevisionId = null,
        model: {
          clusterTemplateRevisions,
          clusterTemplates
        },
        originalCluster,
        intl
      } = this;
      let clusterTemplate;
      clusterTemplateRevisions = clusterTemplateRevisions.slice().filterBy('enabled').filterBy('clusterTemplateId', selectedClusterTemplateId);
      clusterTemplate = clusterTemplates.findBy('id', selectedClusterTemplateId);
      let mapped = clusterTemplateRevisions.map(clusterTemplateRevision => {
        let d = (0, _moment.default)(clusterTemplateRevision.created);
        const isDefault = !Ember.isEmpty(clusterTemplate) ? clusterTemplate.defaultRevisionId === clusterTemplateRevision.id : false;
        let out = {
          id: clusterTemplateRevision.id,
          name: intl.t(`clusterNew.rke.clustersSelectTemplateRevision.select.${isDefault ? 'default' : 'other'}`, {
            name: clusterTemplateRevision.name,
            ago: d.fromNow()
          })
        }; // editing a cluster not a template

        if (this.isEdit && this.cluster.type === 'cluster') {
          const originalKubeVersion = (0, _parseVersion.coerceVersion)(Ember.get(originalCluster, 'rancherKubernetesEngineConfig.kubernetesVersion'));
          const rawRevisionKubeVersion = clusterTemplateRevision.clusterConfig.rancherKubernetesEngineConfig.kubernetesVersion;
          const revisionKubeVersion = (0, _parseVersion.coerceVersion)(rawRevisionKubeVersion);

          const validRange = rawRevisionKubeVersion.endsWith('.x') && _semver.default.validRange(`<=${rawRevisionKubeVersion}`); // Filter revisions with kube versions that are downgrades from the original kube version. We're using satisfies here so
          // that we can support semvers with a .x suffix. When the revision kube version is coerced and ends in .x (which signifies latest patch)
          // it gets coerced into .0 which makes the simple lt check erroenously think the revision version is less than the original version. We
          // still use lt just incase the rawRevisionKubeVersion isn't a valid range and needs to be coereced.


          const isRevisionKubeVersionCompatible = validRange ? _semver.default.satisfies(originalKubeVersion, validRange) : _semver.default.lte(originalKubeVersion, revisionKubeVersion);

          if (!isRevisionKubeVersionCompatible) {
            Ember.set(out, 'disabled', true);
          }
        }

        return out;
      });

      if (clusterTemplate && clusterTemplateRevisionId === null) {
        Ember.run.once(this, this.setDefaultRevisionId, clusterTemplate);
      }

      return mapped.sortBy('created').reverse();
    }),
    allTemplates: Ember.computed('model.clusterTemplates.[]', 'model.clusterTemplateRevisions.[]', function () {
      const remapped = [];
      let {
        clusterTemplates,
        clusterTemplateRevisions
      } = this.model;
      clusterTemplateRevisions = clusterTemplateRevisions.filterBy('enabled');
      clusterTemplateRevisions.forEach(rev => {
        let match = clusterTemplates.findBy('id', Ember.get(rev, 'clusterTemplateId'));

        if (match) {
          remapped.pushObject({
            clusterTemplateId: Ember.get(match, 'id'),
            clusterTemplateName: Ember.get(match, 'displayName'),
            clusterTemplateRevisionId: Ember.get(rev, 'id'),
            clusterTemplateRevisionName: Ember.get(rev, 'name')
          });
        }
      });
      return remapped;
    }),
    canEditForm: Ember.computed('clusterOptErrors.[]', function () {
      return (this.clusterOptErrors || []).length === 0;
    }),
    kubeApiPodSecurityPolicy: Ember.computed('config.services.kubeApi.podSecurityPolicy', {
      get() {
        let pspConfig = Ember.get(this, 'config.services.kubeApi');

        if (typeof pspConfig === 'undefined') {
          return false;
        }

        return Ember.get(pspConfig, 'podSecurityPolicy');
      },

      set(key, value) {
        if (typeof Ember.get(this, 'config.services') === 'undefined') {
          Ember.set(this, 'config.services', Ember.get(this, 'globalStore').createRecord({
            type: 'rkeConfigServices',
            kubeApi: Ember.get(this, 'globalStore').createRecord({
              type: 'kubeAPIService',
              podSecurityPolicy: value
            })
          }));
        } else {
          Ember.set(this, 'config.services', {
            kubeApi: {
              podSecurityPolicy: value
            }
          });
        }

        return value;
      }

    }),
    monitoringProvider: Ember.computed('config.monitoring', {
      get() {
        let monitoringConfig = Ember.get(this, 'config.monitoring');

        if (typeof monitoringConfig === 'undefined') {
          return null;
        }

        return Ember.get(monitoringConfig, 'provider');
      },

      set(key, value) {
        if (typeof Ember.get(this, 'config.monitoring') === 'undefined') {
          Ember.set(this, 'config.monitoring', Ember.get(this, 'globalStore').createRecord({
            type: 'monitoringConfig',
            provider: value
          }));
        } else {
          Ember.set(this, 'config.monitoring', {
            provider: value
          });
        }

        return value;
      }

    }),
    nginxIngressProvider: Ember.computed('config.ingress', {
      get() {
        let ingressConfig = Ember.get(this, 'config.ingress');

        if (typeof ingressConfig === 'undefined') {
          return null;
        }

        return Ember.get(ingressConfig, 'provider');
      },

      set(key, value) {
        if (typeof Ember.get(this, 'config.ingress') === 'undefined') {
          Ember.set(this, 'config.ingress', Ember.get(this, 'globalStore').createRecord({
            type: 'ingressConfig',
            provider: value
          }));
        } else {
          Ember.set(this, 'config.ingress', {
            provider: value
          });
        }

        return value;
      }

    }),
    versionChoices: Ember.computed(`settings.${_constants.default.SETTING.VERSIONS_K8S}`, 'clusterTemplateCreate', function () {
      let out = JSON.parse(Ember.get(this, `settings.${_constants.default.SETTING.VERSIONS_K8S}`) || '{}');
      out = Object.keys(out);
      let patchedOut = [];

      if (this.clusterTemplateCreate) {
        patchedOut = out.map(version => {
          return `${(0, _semver.major)(version)}.${(0, _semver.minor)(version)}.x`;
        });
      }

      return [...out, ...patchedOut];
    }),
    newNodeCount: Ember.computed('initialNodeCounts', 'primaryResource.id', 'existingNodes.@each.clusterId', function () {
      let clusterId = Ember.get(this, 'primaryResource.id');
      let orig = Ember.get(this, 'initialNodeCounts')[clusterId] || 0;
      let cur = Ember.get(this, 'existingNodes').filterBy('clusterId', clusterId).length;

      if (cur < orig) {
        orig = cur;
        Ember.set(Ember.get(this, 'initialNodeCounts'), clusterId, cur);
      }

      return cur - orig;
    }),
    yamlValue: Ember.computed('isEdit', 'pasteOrUpload', 'primaryResource', {
      get() {
        // On edit we should get the cluster fields that are updateable, any fields added during the creation would need the cluster fields at the time
        const intl = Ember.get(this, 'intl');
        let config = this.isEdit ? this.getSupportedFields(Ember.get(this, 'primaryResource'), 'cluster', EXCLUED_CLUSTER_OPTIONS) : this.getClusterFields(this.primaryResource);
        let cpConfig = Ember.get(config, 'rancher_kubernetes_engine_config.cloud_provider');
        config = (0, _util.removeEmpty)(config, EXCLUDED_KEYS, EXCLUDED_CHILDREN_KEYS); // get rid of undefined

        config = JSON.parse(JSON.stringify(config));

        if (cpConfig) {
          cpConfig = (0, _util.removeEmpty)(cpConfig, EXCLUDED_KEYS, EXCLUDED_CHILDREN_KEYS);

          if (cpConfig.name === 'aws' && !cpConfig.awsCloudProvider) {
            config.rancher_kubernetes_engine_config.cloud_provider = { ...config.rancher_kubernetes_engine_config.cloud_provider,
              type: 'cloudProvider',
              awsCloudProvider: {
                type: 'awsCloudProvider'
              }
            };
          } else if (cpConfig.azureCloudProvider) {
            // this is a quick and dirty fix for azure only because it is currently the only cp that works
            // this whole process will be recieving updates shortly so this is a temp fix
            // client_id, secret, & subscription_id are all required so ensure they are there on NEW when a user has entered them but switched to yaml or edit
            // removeEmpty works great except for these fields and adding nested paths doesn't work in removeEmpty
            Object.assign(config.rancher_kubernetes_engine_config.cloud_provider.azureCloudProvider, {
              'aad_client_cert_password': cpConfig.azureCloudProvider.aad_client_cert_password || '',
              'aad_client_id': cpConfig.azureCloudProvider.aad_client_id || '',
              'aad_client_secret': cpConfig.azureCloudProvider.aad_client_secret || '',
              'tenant_id': cpConfig.azureCloudProvider.tenant_id || '',
              'subscription_id': cpConfig.azureCloudProvider.subscription_id || ''
            });
          }
        }

        let yaml = _jsYaml.default.safeDump(config, {
          sortKeys: true
        });

        let lines = yaml.split('\n');
        let out = '';
        lines.forEach((line, idx) => {
          if (line.trim()) {
            if (!this.isEdit) {
              let key = '';
              let commentLines = '';

              if (idx === 0) {
                commentLines = intl.t('').split('\n');
                key = `rkeConfigComment.clusterConfig`;
              } else {
                key = `rkeConfigComment.${line.split(':')[0].trim()}`;
              }

              if (intl.exists(key)) {
                commentLines = intl.t(key).split('\n');
                commentLines.forEach(commentLine => {
                  out += `# ${commentLine.slice(1, commentLine.length - 1)}\n`;
                });
              }
            }

            out += `${line.trimEnd()}\n`;
          }
        });
        return out;
      },

      set(key, value) {
        Ember.run.next(() => {
          Ember.set(this, 'clusterOptErrors', this.checkYamlForRkeConfig(value));
        });
        return value;
      }

    }),
    allErrors: Ember.computed('errors.[]', 'clusterErrors.[]', 'otherErrors.[]', 'clusterOptErrors.[]', function () {
      let {
        errors,
        clusterErrors,
        clusterOptErrors,
        otherErrors
      } = this;
      return [...(errors || []), ...(clusterErrors || []), ...(clusterOptErrors || []), ...(otherErrors || [])];
    }),
    cisProfileDisplay: Ember.computed('cisProfile', 'cisScanProfileOptions', function () {
      const profile = Ember.get(this, 'cisScanProfileOptions').find(option => option.value === Ember.get(this, 'cisProfile'));
      return profile ? profile.label : '';
    }),
    cisScanProfileOptions: Ember.computed('cisProfile', 'cisHelpers.cisScanProfileOptions', function () {
      const selectedProfile = Ember.get(this, 'cisProfile');
      const options = Ember.get(this, 'cisHelpers.cisScanProfileOptions');
      const foundProfile = options.find(option => option.value === selectedProfile);

      if (!foundProfile && selectedProfile) {
        const splitProfile = selectedProfile.split(' ');
        splitProfile[1] = (0, _util.ucFirst)(splitProfile[1]);
        const missingOption = {
          label: splitProfile.join(' '),
          value: splitProfile
        };
        return [missingOption, ...options];
      }

      return options;
    }),
    provider: Ember.computed('model.cluster.clusterProvider', 'model.provider', function () {
      return Ember.get(this, 'model.provider') ? Ember.get(this, 'model.provider') : Ember.get(this, 'model.cluster.clusterProvider');
    }),

    initScheduledClusterScan() {
      // We need to wait for benchmarks to be available before we can actually create the profiles
      if (this.cisHelpers.cisScanBenchmarks.length === 0) {
        return;
      }

      const kubernetesVersion = Ember.get(this, 'primaryResource.rancherKubernetesEngineConfig.kubernetesVersion');
      const defaultProfileOption = this.cisHelpers.getDefaultCisScanProfileOption(kubernetesVersion);
      const scheduledClusterScan = Ember.get(this, 'primaryResource.scheduledClusterScan') || Ember.get(this, 'scheduledClusterScan');
      const scanConfig = Ember.get(this, 'primaryResource.scheduledClusterScan.scanConfig') || this.cisHelpers.profileToClusterScanConfig(defaultProfileOption);
      const scheduleConfig = Ember.get(this, 'primaryResource.scheduledClusterScan.scheduleConfig') || Ember.get(this, 'scheduledClusterScan.scheduleConfig');
      const cisProfile = this.cisHelpers.clusterScanConfigToProfile(scanConfig);
      const enabled = Ember.get(this, 'primaryResource.scheduledClusterScan.enabled');
      Ember.set(this, 'scheduledClusterScan', scheduledClusterScan);
      Ember.set(this, 'scheduledClusterScan.enabled', typeof enabled === 'string' ? enabled === 'true' : !!enabled);
      Ember.set(this, 'scheduledClusterScan.scanConfig', scanConfig);
      Ember.set(this, 'scheduledClusterScan.scheduleConfig', scheduleConfig);
      Ember.set(this, 'cisProfile', cisProfile);
    },

    getDefaultUpgradeStrategy() {
      return this.globalStore.createRecord({
        type: 'nodeUpgradeStrategy',
        nodeDrainInput: this.globalStore.createRecord({
          type: 'nodeDrainInput'
        })
      });
    },

    initUpgradeStrategy() {
      const upgradeStrategy = Ember.get(this, 'primaryResource.rancherKubernetesEngineConfig.upgradeStrategy');
      const defaultUpgradeStrategy = this.getDefaultUpgradeStrategy();
      const source = upgradeStrategy ? upgradeStrategy : defaultUpgradeStrategy;
      const maxUnavailableControlplane = Ember.get(source, 'maxUnavailableControlplane');
      const maxUnavailableWorker = Ember.get(source, 'maxUnavailableWorker') || '';
      const maxUnavailableUnit = maxUnavailableWorker.includes('%') ? 'percentage' : 'count';
      const drainInput = Ember.get(source, 'nodeDrainInput');
      const timeout = Ember.get(source, 'nodeDrainInput.timeout');
      const drainStatusStr = (Ember.get(source, 'drain') || false).toString();
      Ember.set(this, 'upgradeStrategy.maxUnavailableControlplane', maxUnavailableControlplane);
      Ember.set(this, 'upgradeStrategy.maxUnavailableWorker', maxUnavailableWorker.replace('%', ''));
      Ember.set(this, 'upgradeStrategy.maxUnavailableUnit', maxUnavailableUnit);
      Ember.set(this, 'upgradeStrategy.nodeDrainInput', drainInput ? drainInput : {});
      Ember.set(this, 'upgradeStrategy.drain', drainStatusStr); // This ensures the UI won't initialize the timeout below the minimum.

      if (timeout !== undefined && timeout <= 0) {
        const defaultTimeout = Ember.get(defaultUpgradeStrategy, 'nodeDrainInput.timeout');
        Ember.set(this, 'upgradeStrategy.nodeDrainInput.timeout', defaultTimeout);
      }
    },

    getClusterFields(primaryResource) {
      let pojoPr = JSON.parse(JSON.stringify((0, _util.removeEmpty)(primaryResource, EXCLUDED_KEYS, EXCLUDED_CHILDREN_KEYS)));
      let decamelizedObj = {};
      decamelizedObj = (0, _util.keysToDecamelize)(pojoPr, void 0, ['type', 'azureCloudProvider']);
      return decamelizedObj;
    },

    checkYamlForRkeConfig(yamlValue) {
      let decamledYaml = this.parseOptsFromYaml(yamlValue);
      let errOut = null;

      if (decamledYaml && Ember.isEmpty(decamledYaml.rancherKubernetesEngineConfig)) {
        errOut = [`Cluster Options Parse Error: Missing Rancher Kubernetes Engine Config`];
      }

      return errOut;
    },

    pastedYaml(cm) {
      Ember.run.next(() => {
        Ember.set(this, 'clusterOptErrors', this.checkYamlForRkeConfig(cm.doc.getValue()));
      });
    },

    parseOptsFromYaml(yamlValue) {
      let yamlConfig;

      try {
        yamlConfig = _jsYaml.default.safeLoad(yamlValue);
      } catch (err) {
        Ember.set(this, 'clusterOptErrors', [`Cluster Options Parse Error: ${err.snippet} - ${err.message}`]);
        return;
      }

      return (0, _util.keysToCamel)(yamlConfig);
    },

    getOptsFromYaml() {
      let {
        yamlValue
      } = this;
      let decamledYaml = this.parseOptsFromYaml(yamlValue);

      if (!decamledYaml) {
        return;
      }

      if (decamledYaml && Ember.isEmpty(decamledYaml.rancherKubernetesEngineConfig)) {
        Ember.set(this, 'clusterOptErrors', [`Cluster Options Parse Error: Missing rancher_kubernetes_engine_config key`]);
        return;
      }

      decamledYaml.type = this.primaryResource.type;
      return this.globalStore.createRecord(decamledYaml);
    },

    buildClusterAnswersFromConfig(cluster, questions) {
      let {
        backupStrategy
      } = this;
      let answers = {};

      if (questions && questions.length) {
        questions.forEach(quest => {
          // ember.object.get doesn't support bracket notation, this provides support for the notation by replace some[0].object with some.0.object
          // which ember does support. I'm putting this here instead of replacing the answer variables because I think it's more intuitive to specify
          // variables with the bracket notation.
          const varaible = quest.variable.replaceAll('[', '.').replaceAll(']', '');
          let match = Ember.get(cluster, varaible);

          if (match) {
            answers[quest.variable] = match;
          } else {
            if (quest.variable.includes('s3BackupConfig') && backupStrategy === 'local') {
              // we get into this case when a RKE Template creator lets the user override the backup strategy, and they've changed it to local.
              // if we dont send the answers with nulls the s3backupconfig will be created on the backend from its existence on the RKE Template revision
              answers[quest.variable] = null;
            }
          }
        });
      }

      return answers;
    },

    willSave() {
      const {
        applyClusterTemplate,
        cluster,
        configField: field
      } = this;
      let ok = true;
      this.checkKubernetesVersionSemVer();

      if (Ember.get(this, 'upgradeStrategy.maxUnavailableUnit') === 'percentage') {
        Ember.set(this, 'upgradeStrategy.maxUnavailableWorker', `${Ember.get(this, 'upgradeStrategy.maxUnavailableWorker')}%`);
      }

      if (!Ember.get(this, 'upgradeStrategy.drain')) {
        Ember.set(this, 'upgradeStrategy.nodeDrainInput', null);
      }

      Ember.set(cluster, 'rancherKubernetesEngineConfig.upgradeStrategy', Ember.get(this, 'upgradeStrategy'));

      if (Ember.get(this, 'scheduledClusterScan.enabled')) {
        try {
          Ember.set(this, 'scheduledClusterScan.scanConfig', this.cisHelpers.profileToClusterScanConfig(Ember.get(this, 'cisProfile')));
        } catch (ex) {
          Ember.set(this, 'scheduledClusterScan.scanConfig', null);
          console.error('There was a problem attempting to map a profile to a clusterScanConfig', ex, Ember.get(this, 'cisProfile'));
        }
      } else {
        Ember.set(this, 'scheduledClusterScan.scanConfig', null);
        Ember.set(this, 'scheduledClusterScan.scheduleConfig', null);
      }

      Ember.set(cluster, 'scheduledClusterScan', Ember.get(this, 'scheduledClusterScan'));
      Ember.set(this, 'isWindowsPreferedCluster', cluster === null || cluster === void 0 ? void 0 : cluster.windowsPreferedCluster);

      if (this.pasteOrUpload && this.mode !== 'view') {
        const newOpts = this.getOptsFromYaml();

        if (newOpts) {
          if (this.updateFromYaml) {
            this.updateFromYaml(newOpts);
          }
        } else {
          return false;
        }
      }

      if (Ember.get(cluster, 'localClusterAuthEndpoint')) {
        if (!Ember.get(cluster, 'rancherKubernetesEngineConfig') || Ember.isEmpty(Ember.get(cluster, 'rancherKubernetesEngineConfig'))) {
          delete cluster.localClusterAuthEndpoint;
        }
      }

      Ember.set(this, 'errors', null); // Fix up agent env vars - remove any with empty 'valueFrom' properties

      const agentEnvVars = Ember.get(this, 'cluster.agentEnvVars') || [];
      let didChangeEnvVars = false;
      agentEnvVars.forEach(envVar => {
        if (envVar.valueFrom && Object.getOwnPropertyNames(envVar.valueFrom).length === 0) {
          delete envVar.valueFrom;
          didChangeEnvVars = true;
        }
      });

      if (didChangeEnvVars) {
        Ember.set(this, 'cluster.agentEnvVars', agentEnvVars);
      }

      ok = this.validate();

      if (ok) {
        if (Ember.typeOf(cluster.clearProvidersExcept) === 'function' || applyClusterTemplate && Ember.typeOf(this.buildClusterAnswersFromConfig) === 'function') {
          if (applyClusterTemplate) {
            let questions = Ember.get(this, 'model.clusterTemplateRevision.questions') || [];
            let answers = [];
            let errors = null;
            answers = this.buildClusterAnswersFromConfig(cluster, questions);

            if (questions.length > 0) {
              errors = this.checkRequiredQuestionsHaveAnswers(questions, answers);
            } // When creating from an RKE Cluster Template with weave networking, add provider password


            const networkType = Ember.get(cluster, _constants.default.NETWORK_QUESTIONS.PLUGIN);

            if (networkType === WEAVE) {
              const provider = Ember.get(cluster, _constants.default.NETWORK_QUESTIONS.WEAVE_NETWORK_PROVIDER);

              if (provider) {
                answers[_constants.default.NETWORK_QUESTIONS.WEAVE_PASSWORD] = (0, _util.randomStr)(16, 16, 'password');
              }
            }

            if (Ember.isEmpty(errors)) {
              Ember.set(cluster, 'answers', {
                values: answers
              });
              this.cluster.clearConfigFieldsForClusterTemplate();
            } else {
              Ember.set(this, 'errors', errors);
              ok = false;
            }
          } else {
            cluster.clearProvidersExcept(field);
          }
        }
      } else {
        // If validation fails, we stay on the form, so we need to undo the formatting of the max unavailable worker
        // otherwise we keep adding a '%' to the input field
        const maxUnavailableWorker = Ember.get(this, 'upgradeStrategy.maxUnavailableWorker');
        Ember.set(this, 'upgradeStrategy.maxUnavailableWorker', maxUnavailableWorker.replace('%', ''));
      }

      return ok;
    },

    checkRequiredQuestionsHaveAnswers(questions, answers) {
      const {
        intl
      } = this;
      const required = questions.filterBy('required', true);
      const errors = [];

      if (questions.length > 0 && required.length > 0) {
        required.forEach(rq => {
          if (!answers[rq.variable]) {
            errors.push(intl.t('validation.required', {
              key: rq.variable
            }));
          }
        });
      }

      return errors;
    },

    validate() {
      let {
        config,
        intl
      } = this;

      this._super(...arguments);

      let errors = this.errors || [];

      if (this.clusterTemplateCreate) {
        const revision = this.model.clusterTemplateRevision;

        if (revision) {
          errors.pushObjects(revision.validationErrors());
          const cloudProvider = Ember.get(revision, 'clusterConfig.rancherKubernetesEngineConfig.cloudProvider.name');
          const azureProvider = Ember.get(revision, 'clusterConfig.rancherKubernetesEngineConfig.cloudProvider.azureCloudProvider') || {};

          if (cloudProvider === 'azure') {
            const azureQuestions = (Ember.get(revision, 'questions') || []).map(x => x.variable.replace(/^rancherKubernetesEngineConfig\.cloudProvider\.azureCloudProvider\./, ''));
            const requiredFields = Object.keys(_cloudProviderInfo.azure).filter(k => _cloudProviderInfo.azure[k].required);
            requiredFields.forEach(key => {
              if (!Ember.get(azureProvider, key) && !azureQuestions.includes(key)) {
                errors.push(intl.t('validation.requiredOrOverride', {
                  key
                }));
              }
            });
          }
        }
      } else {
        if (!Ember.get(this, 'isCustom')) {
          errors.pushObjects(Ember.get(this, 'nodePoolErrors'));
        }

        if (Ember.get(config, 'cloudProvider.name') === 'azure' && !this.applyClusterTemplate) {
          Object.keys(_cloudProviderInfo.azure).forEach(key => {
            if (Ember.get(_cloudProviderInfo.azure, `${key}.required`) && !Ember.get(config, `cloudProvider.azureCloudProvider.${key}`)) {
              if (this.isNew || this.isEdit && key !== 'aadClientSecret') {
                errors.push(intl.t('validation.required', {
                  key
                }));
              }
            }
          });
        }
      }

      if (Ember.get(config, 'services.kubeApi.podSecurityPolicy') && !Ember.get(this, 'primaryResource.defaultPodSecurityPolicyTemplateId')) {
        errors.push(intl.t('clusterNew.psp.required'));
      }

      if (Ember.get(this, 'config.services.etcd.snapshot')) {
        errors = this.validateEtcdService(errors);
      }

      if (Ember.get(this, 'primaryResource.localClusterAuthEndpoint.enabled')) {
        errors = this.validateAuthorizedClusterEndpoint(errors);
      }

      Ember.set(this, 'errors', errors);
      return Ember.get(this, 'errors.length') === 0;
    },

    validateAuthorizedClusterEndpoint(errors) {
      let {
        localClusterAuthEndpoint
      } = Ember.get(this, 'primaryResource');
      let {
        caCerts,
        fqdn
      } = localClusterAuthEndpoint;

      if (caCerts) {
        if (!(0, _util.validateCertWeakly)(caCerts)) {
          errors.push(this.intl.t('newCertificate.errors.cert.invalidFormat'));
        }
      } // FQDN here can include port number


      if (fqdn) {
        const fqdnParts = fqdn.split(':');

        if (fqdnParts.length > 2) {
          errors.push(this.intl.t('authorizedEndpoint.fqdn.invalid'));
        }

        errors = (0, _validate.validateHostname)(fqdnParts[0], 'FQDN', Ember.get(this, 'intl'), {
          restricted: true
        }, errors);

        if (fqdnParts.length === 2) {
          const port = parseInt(fqdnParts[1], 10);

          if (isNaN(port) || port < 1 || port > 65535) {
            errors.push(this.intl.t('authorizedEndpoint.fqdn.invalid'));
          }
        }
      }

      return errors;
    },

    validateEtcdService(errors) {
      const etcdService = Ember.get(this, 'config.services.etcd') || {};
      const {
        creation,
        retention
      } = etcdService;
      const that = this;

      function checkDurationIsValid(duration, type) {
        // exact matching on these inputs
        // patternList = 12h12m12s || 12h12m || 12m12s  || 12h12s || 12h || 12m || 12s
        let patternList = [/^(\d+)(h)(\d+)(m)(\d+)(s)$/, /^(\d+)(h)(\d+)(m)$/, /^(\d+)(m)(\d+)(s)$/, /^(\d+)(h)(\d+)(s)$/, /^(\d+)(h)$/, /^(\d+)(m)$/, /^(\d+)(s)$/];
        let match = patternList.filter(p => p.test(duration));

        if (match.length === 0) {
          durationError(duration, type);
        }

        return;
      }

      function durationError(entry, type) {
        return errors.push(Ember.get(that, 'intl').t('clusterNew.rke.etcd.error', {
          type,
          entry
        }));
      }

      checkDurationIsValid(creation, 'Creation');
      checkDurationIsValid(retention, 'Reteintion');
      return errors;
    },

    doneSaving(neu) {
      let {
        close
      } = this;

      if (Ember.get(this, 'isCustom')) {
        this.loadToken();
      } else {
        if (close) {
          close(neu);
        }
      }

      return Ember.RSVP.resolve();
    },

    doneSaveFailed() {
      // Ensure we remove the % from the max unavailable worker field, otherwise they keep getting added
      // if the save fails due to a form issue
      const maxUnavailableWorker = Ember.get(this, 'upgradeStrategy.maxUnavailableWorker');
      Ember.set(this, 'upgradeStrategy.maxUnavailableWorker', maxUnavailableWorker.replace('%', ''));
      return Ember.RSVP.resolve();
    },

    loadToken() {
      const cluster = Ember.get(this, 'primaryResource');

      if (cluster.getOrCreateToken) {
        Ember.set(this, 'loading', true);
        return cluster.getOrCreateToken().then(token => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          Ember.setProperties(this, {
            token,
            step: 2,
            loading: false
          });
        }).catch(err => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          Ember.get(this, 'growl').fromError('Error getting command', err);
          Ember.set(this, 'loading', false);
        });
      } else {
        return;
      }
    },

    findExcludedKeys(resourceFields) {
      Object.keys(resourceFields).forEach(key => {
        const type = resourceFields[key].type;

        if (type.startsWith('map[')) {
          const t = type.slice(4, type.length - 1);
          const s = Ember.get(this, 'globalStore').getById('schema', t.toLowerCase());

          if (s) {
            const underlineKey = camelToUnderline(key);

            if (EXCLUDED_KEYS.indexOf(underlineKey) === -1) {
              EXCLUDED_KEYS.push(underlineKey);
            }
          }
        }
      });
    },

    getResourceFields(type) {
      const schema = Ember.get(this, 'globalStore').getById('schema', type.toLowerCase());
      let resourceFields = null;

      if (schema) {
        resourceFields = Ember.get(schema, 'resourceFields');
        this.findExcludedKeys(resourceFields);
      }

      return resourceFields;
    },

    getFieldValue(field, type) {
      let resourceFields;
      const out = {};

      if (type === 'map[json]' && Ember.get(field || {}, 'Resources') && Ember.get(field || {}, 'kind') === 'EncryptionConfiguration') {
        // this is a one off change to handle rancherKubernetesEngineConfig.services.kubeAPI.secretsEncryptionConfig.customConfig arbitrary JSON problem seeing in #30555.
        return field;
      }

      if (type.startsWith('map[')) {
        type = type.slice(4, type.length - 1);
        resourceFields = this.getResourceFields(type);

        if (resourceFields) {
          if (field) {
            Object.keys(field).forEach(key => {
              out[camelToUnderline(key)] = this.getFieldValue(field[key], type);
            });
            return out;
          } else {
            return null;
          }
        } else {
          if (field) {
            Object.keys(field).forEach(key => {
              out[camelToUnderline(key)] = field[key];
            });
            return out;
          } else {
            return null;
          }
        }
      } else if (type.startsWith('array[')) {
        type = type.slice(6, type.length - 1);
        resourceFields = this.getResourceFields(type);

        if (resourceFields) {
          return field ? field.map(item => this.getFieldValue(item, type)) : null;
        } else {
          return field ? field.map(item => item) : null;
        }
      } else {
        resourceFields = this.getResourceFields(type);

        if (resourceFields) {
          Object.keys(resourceFields).forEach(key => {
            if (!Ember.isEmpty(field) && (typeof field !== 'object' || Object.keys(field).length)) {
              out[camelToUnderline(key, type !== 'rkeConfigServices')] = this.getFieldValue(field[key], resourceFields[key].type);
            }
          });
          return out;
        } else {
          return field;
        }
      }
    },

    getSupportedFields(source, targetField, excludeFields = []) {
      const out = {};
      const resourceFields = this.getResourceFields(targetField);
      Object.keys(resourceFields).filter(key => resourceFields[key].update && excludeFields.indexOf(key) === -1).forEach(key => {
        const field = Ember.get(source, key);
        const type = resourceFields[key].type;
        const value = this.getFieldValue(field, type);
        out[camelToUnderline(key)] = value;
      });
      return out;
    },

    initNonTemplateCluster() {
      if (Ember.get(this, 'isNew')) {
        this.createRkeConfigWithDefaults();
      } else {
        this.initPrivateRegistries();
      }

      this.initBackupConfigs();
      Ember.run.scheduleOnce('afterRender', this, this.initRootDockerDirectory);
    },

    initTemplateCluster() {
      this.initBackupConfigs();
      this.initClusterTemplateQuestions();
    },

    initNodeCounts() {
      const counts = {};
      let initialVersion = Ember.get(this, 'config.kubernetesVersion');
      Ember.set(this, 'existingNodes', this.globalStore.all('node'));
      this.globalStore.findAll('node').then(all => {
        all.forEach(node => {
          const id = Ember.get(node, 'clusterId');
          counts[id] = (counts[id] || 0) + 1;
        });
        this.notifyPropertyChange('initialNodeCounts');
      });

      if (this.isEdit && !Ember.isEmpty(Ember.get(this, 'cluster.appliedSpec.rancherKubernetesEngineConfig.kubernetesVersion'))) {
        initialVersion = this.cluster.appliedSpec.rancherKubernetesEngineConfig.kubernetesVersion;
      }

      Ember.setProperties(this, {
        initialVersion,
        initialNodeCounts: counts
      });
    },

    initPrivateRegistries() {
      const config = Ember.get(this, 'config');

      if (Ember.get(config, 'privateRegistries.length') > 0) {
        const registry = Ember.get(config, 'privateRegistries.firstObject');
        Ember.setProperties(this, {
          registry: 'custom',
          registryUrl: Ember.get(registry, 'url'),
          registryUser: Ember.get(registry, 'user'),
          registryPass: Ember.get(registry, 'password')
        });
      }
    },

    createRkeConfigWithDefaults() {
      const {
        globalStore,
        versionChoices
      } = this;
      const defaultVersion = Ember.get(this, `settings.${_constants.default.SETTING.VERSION_SYSTEM_K8S_DEFAULT_RANGE}`);
      const satisfying = (0, _parseVersion.maxSatisfying)(versionChoices, defaultVersion);
      const out = {};
      const rkeConfig = globalStore.createRecord({
        type: 'rancherKubernetesEngineConfig',
        ignoreDockerVersion: true,
        kubernetesVersion: satisfying,
        authentication: globalStore.createRecord({
          type: 'authnConfig',
          strategy: 'x509'
        }),
        dns: globalStore.createRecord({
          type: 'dnsConfig',
          nodelocal: globalStore.createRecord({
            type: 'nodelocal',
            ip_address: '',
            node_selector: null,
            update_strategy: {}
          })
        }),
        network: globalStore.createRecord({
          type: 'networkConfig',
          plugin: 'canal',
          options: {
            flannel_backend_type: DEFAULT_BACKEND_TYPE
          }
        }),
        ingress: globalStore.createRecord({
          type: 'ingressConfig',
          provider: 'nginx',
          defaultBackend: false
        }),
        monitoring: globalStore.createRecord({
          type: 'monitoringConfig',
          provider: 'metrics-server'
        }),
        services: globalStore.createRecord({
          type: 'rkeConfigServices',
          kubeApi: globalStore.createRecord({
            type: 'kubeAPIService',
            podSecurityPolicy: false,
            serviceNodePortRange: '30000-32767',
            secretsEncryptionConfig: globalStore.createRecord({
              type: 'secretsEncryptionConfig'
            })
          }),
          etcd: globalStore.createRecord({
            type: 'etcdService',
            snapshot: false,
            backupConfig: globalStore.createRecord({
              type: 'backupConfig',
              enabled: true
            }),
            extraArgs: {
              'heartbeat-interval': 500,
              'election-timeout': 5000
            }
          })
        }),
        upgradeStrategy: this.getDefaultUpgradeStrategy()
      });
      Ember.setProperties(out, {
        rancherKubernetesEngineConfig: rkeConfig,
        enableNetworkPolicy: false
      });

      if (this.isNew) {
        Ember.set(out, 'localClusterAuthEndpoint', globalStore.createRecord({
          type: 'localClusterAuthEndpoint',
          enabled: true
        }));
      }

      Ember.run.scheduleOnce('afterRender', this, this.notifyConfigUpdate, out);
    },

    notifyConfigUpdate(out) {
      Ember.setProperties(this.primaryResource, out);
      this.notifyPropertyChange('config');
      console.log('config updated');
    },

    migrateLegacyEtcdSnapshotSettings() {
      const {
        config
      } = this;
      let {
        retention,
        creation,
        backupConfig
      } = config.services.etcd;
      let creationMatch = creation.match(/^((\d+)h)?((\d+)m)?((\d+)s)?$/);
      let momentReady = [creationMatch[2], creationMatch[4], creationMatch[6]];

      if (momentReady[1] && momentReady[1] < 30) {
        // round min down since new settting is in intval hours
        momentReady[1] = 0;
      } else if (momentReady[1] && momentReady[1] >= 30) {
        // round up to the nearest hour
        momentReady[0] = parseInt(momentReady[0], 10) + 1;
        momentReady[1] = 0;
      }

      if ((!momentReady[0] || momentReady[0] === 0) && momentReady[1] === 0) {
        // if both hours and min are zero set hour to 1;
        momentReady[0] = 1;
      }

      let toMoment = {
        hours: momentReady[0] ? momentReady[0] : 0,
        minutes: momentReady[1] ? momentReady[1] : 0,
        seconds: momentReady[2] ? momentReady[2] : 0
      };

      const parsedDurationAsHours = _moment.default.duration(toMoment).hours();

      Ember.setProperties(this, {
        legacyRetention: retention,
        hasLegacySnapshotSettings: true
      });

      if (backupConfig) {
        Ember.setProperties(config.services.etcd, {
          'backupConfig.enabled': true,
          'backupConfig.intervalHours': parsedDurationAsHours,
          snapshot: false
        });
      } else {
        backupConfig = this.globalStore.createRecord({
          type: 'backupConfig',
          intervalHours: parsedDurationAsHours,
          enabled: true
        });
        Ember.setProperties(config.services.etcd, {
          backupConfig,
          snapshot: false
        });
      }
    },

    initRootDockerDirectory() {
      Ember.set(this, 'defaultDockerRootDir', Ember.get(this.globalStore.getById('schema', 'cluster').getCreateDefaults(), 'dockerRootDir'));
    },

    initBackupConfigs() {
      const etcd = Ember.get(this, 'config.services.etcd');

      if (etcd) {
        if (etcd.snapshot) {
          Ember.set(this, 'backupStrategy', 'local');
          this.migrateLegacyEtcdSnapshotSettings();
        } else if (etcd.backupConfig && etcd.backupConfig.s3BackupConfig) {
          Ember.set(this, 'backupStrategy', 's3');
        } else if (!etcd.snapshot && !etcd.backupConfig) {
          const backupConfig = Ember.get(this, 'globalStore').createRecord({
            enabled: false,
            type: 'backupConfig'
          });
          Ember.set(etcd, 'backupConfig', backupConfig);
        } else {
          Ember.set(this, 'backupStrategy', 'local');
        }
      }
    },

    initClusterTemplateQuestions() {
      let {
        clusterTemplateQuestions
      } = this; // Use the existing cluster config values as defaults for the questions
      // because one or more values could already be overridden.

      let primaryResource = Ember.get(this, 'config');

      if (clusterTemplateQuestions && clusterTemplateQuestions.length > 0 && primaryResource) {
        clusterTemplateQuestions.forEach(question => {
          let path = question.variable;

          if (!question.variable.includes('uiOverride') && question.default) {
            (0, _emberDeepSet.default)(primaryResource, path, question.default);
          }

          Ember.set(question, 'primaryResource', primaryResource);
          Ember.defineProperty(question, 'default', Ember.computed.alias(`primaryResource.${path}`));
        });
      }
    },

    checkKubernetesVersionSemVer() {
      let {
        clusterTemplateCreate = false,
        config: {
          kubernetesVersion
        },
        model: {
          clusterTemplateRevision = {}
        }
      } = this;
      let questions = [];

      if (clusterTemplateRevision && clusterTemplateRevision.questions) {
        questions = clusterTemplateRevision.questions;
      }

      const kubernetesVersionOverrideExists = questions.findBy('variable', 'rancherKubernetesEngineConfig.kubernetesVersion');

      if (clusterTemplateCreate) {
        if (kubernetesVersionOverrideExists) {
          if (kubernetesVersionOverrideExists.satisfies) {
            // we have a user defined override and this means we dont need satisfies because it can be anything
            delete kubernetesVersionOverrideExists.satisfies;
          }
        } else {
          if (kubernetesVersion.endsWith('x')) {
            this.send('addOverride', true, {
              path: 'rancherKubernetesEngineConfig.kubernetesVersion'
            }, true);
          }
        }
      }
    },

    parseKubernetesVersionSemVer(cluster, question) {
      let {
        kubernetesVersion
      } = cluster.rancherKubernetesEngineConfig;

      let coercedVersion = _semver.default.coerce(kubernetesVersion);

      let satisfies = `>=${coercedVersion.major}.${coercedVersion.minor}.${coercedVersion.patch} <${coercedVersion.major}.${coercedVersion.minor + 1}`;
      Ember.set(question, 'satisfies', satisfies);
      return question;
    },

    setupComponent() {
      this.initUpgradeStrategy();
      this.initScheduledClusterScan();
    },

    setDefaultRevisionId(clusterTemplate) {
      Ember.set(this, 'clusterTemplateRevisionId', clusterTemplate.defaultRevisionId);
    }

  });

  _exports.default = _default;
});